import { Github, Mail } from "lucide-react";

export const Hero = () => (
  <div className="h-1/2 my-16 flex items-center justify-center bg-gradient-to-br from-blue-100 to-indigo-100 py-24">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <div className="space-y-8">
        <h1 className="text-5xl font-bold text-gray-900">
          Hi, I'm{" "}
          <span className="text-indigo-600 text-semibold">Prasun Sitaula</span>
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Full Stack Developer passionate about building exceptional digital
          experiences. Specialized in React, NextJS, Node.js, and modern web
          technologies.
        </p>
        <div className="flex justify-center space-x-6">
          <a
            href="https://github.com/PRASUN-SITAULA"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 hover:text-gray-900"
          >
            <Github size={24} />
          </a>
          <a
            href="mailto:psitaula209@gmail.com"
            className="text-gray-600 hover:text-gray-900"
          >
            <Mail size={24} />
          </a>
        </div>
      </div>
    </div>
  </div>
);
