import React from "react";
import { Hero } from "../components/Hero";
import Footer from "../components/Footer";
import About from "../components/About";
import Skills from "../components/Skills";

export default function HomePage() {
  return (
    <>
      <Hero />
      <About />
      <Skills />
      <Footer />
    </>
  );
}
