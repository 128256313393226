import { Link } from "react-router-dom";
import { HomeIcon, Code } from "lucide-react";

export const NavBar = () => (
  <nav className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-md shadow-sm z-50">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <Link to="/" className="font-bold text-xl text-gray-800">
          Portfolio
        </Link>
        <div className="flex space-x-4">
          <Link
            to="/"
            className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md flex items-center gap-2"
          >
            <HomeIcon size={18} />
            Home
          </Link>
          <Link
            to="/projects"
            className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md flex items-center gap-2"
          >
            <Code size={18} />
            Projects
          </Link>
        </div>
      </div>
    </div>
  </nav>
);
