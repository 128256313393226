import React from "react";

const ProjectCard = ({ title, description, image, link }) => {
  return (
    <div className="bg-gray-200 rounded-lg shadow-lg overflow-hidden">
      <img
        src={image || "/project_profile.jpeg"}
        alt={title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <a
          href={link}
          className="text-indigo-600 hover:text-indigo-800 font-bold"
        >
          View Project
        </a>
      </div>
    </div>
  );
};

const Projects = () => {
  const projects = [
    {
      title: "Health Tracker",
      description: "A web application for tracking various health metrics. ",
      image: "",
      link: "https://health-track-virid.vercel.app",
    },
    {
      title: "Vehicle Detection and Tracking.",
      description:
        "Our Minor Project using YOLO for vehicle detection, counting and tracking from videos.",
      image: "",
      link: "https://github.com/NayanPandey371/Vehicle-Detection-and-Tracking.git",
    },
    {
      title: "Career Quest",
      description: "A web app that utilizes web scrapping for job listing.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/careerQuest",
    },
    {
      title: "Chit Chat",
      description: "A NextJS messaging app.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/chitChat",
    },
    {
      title: "Carbon Wise",
      description:
        "A LLM powered web app that calculates the carbon footprint of a family and gives advisory to reduce the carbon footprint.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/carbonWise.git",
    },
    {
      title: "gym-exercises.",
      description:
        "A simple react app that used API to fetch exercises and display them. It also has a search bar to search for exercises.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/gym-exercises.git",
    },
    {
      title: "Blog Application",
      description: "A simple blog application in react.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/Blog-Application.git",
    },
    {
      title: "Chat Application in Django.",
      description: "A chatting site in django.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/Chat-Application.git",
    },
    {
      title: "Calorie Counter.",
      description: "A simple Django site that tracks calories consumed.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/Calorie-Counter.git",
    },
    {
      title: "Alumni Tracker",
      description:
        "A web application based on Django for tracking college alumni. It was a software engineering project continued on by our team. We added features like search functionality and dockerized the application for deployment.",
      image: "",
      link: "https://github.com/PRASUN-SITAULA/Alumni-Tracker.git",
    },
  ];

  return (
    <section id="projects" className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">My Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
