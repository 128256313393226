import React from "react";
import ProfileImage from "../assets/images/profile.jpg";
import { Github } from "lucide-react";

const About = () => {
  return (
    <section id="about" className="py-16 bg-white">
      <div className="container mx-auto px-6 ">
        <h2 className="text-3xl font-bold text-center mb-8">About Me</h2>
        <div className="flex flex-col md:flex-row items-center justify-center gap-x-16">
          <div className="mb-8 md:mb-0">
            <img
              src={ProfileImage}
              alt="About Me"
              className="w-60 h-60 rounded-full shadow-lg object-cover"
            />
          </div>
          <div className="md:w-1/2 md:pl-10">
            <p className="text-gray-600 mb-4 text-left">
              I'm a passionate web developer with expertise in NextJs, React,
              Node.js, and modern web technologies. With a keen eye for design
              and a love for clean code, I create engaging and user-friendly web
              applications.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://github.com/PRASUN-SITAULA"
                className="text-indigo-600 hover:text-indigo-800"
              >
                <Github className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
